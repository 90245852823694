import axios from 'axios';
import router from "@/router";
import store from "@/state/store";
import { getTenantFromSubdomain } from "@/utils/tenant";
import getEnv from "@/utils/env";

const staticPath = "/api/tcfd/v1";
const api = axios.create({
    baseURL: staticPath,
    timeout: 5000,
});

// const baseURL = process.env.VUE_APP_URL;
// const esgUrl = process.env.VUE_APP_API_ESG_SERVICE_URL;

// 打ESG平台的API
const esgApi = axios.create({
    baseURL: getEnv("VUE_APP_API_ESG_SERVICE_URL"),
    timeout: 5000,
});

function getUrl(url) {
    // 依照subdomain取找正確的oauth2 server
    const host = window.location.origin
    const tenantId = getTenantFromSubdomain()
    if (!tenantId || 'localhost' === tenantId) {
      // 如果沒有抓到tenantId，則使用預設oauth2 server
        return `${url}`
    } else {
        // 取得baseURL中的後半段path : /api/oc64/v1/
        const path = new URL(`${url}`).pathname;
        return host + path ;
    }
}

api.interceptors.request.use(config => {

        const user = JSON.parse(sessionStorage.getItem('user'));
        // tenantId會從sso sessionStorage取得
        let tenantId = JSON.parse(sessionStorage.getItem('user'))?.tenantId;
        if (user && user.token) {
            config.headers.Authorization = "Bearer " + user.token;
        } else {
            // 沒有token call api會console
            // 登入時需要call api 判斷帳號的狀態，所以不印錯誤
            console.log("")
        }
        const customTenantIdHeader = 'x-tenant-id'
        config.headers["Content-Type"] = "application/json";
        if (tenantId) {
            config.headers[`${customTenantIdHeader}`] = tenantId
        }
        return config;
    },
)


api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response?.status && error.response.status === 401) {
            store.dispatch('auth/setSessionExpired').then(() => {
                console.log('401 error');
                sessionStorage.clear();
                window.location.href = window.location.origin + '/login';
            });
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 403) {
            console.log('403 error');
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 404) {
            console.log('404 error');
            router.push({name: '404'});
        } else if (error.code ==='ECONNABORTED') {
            console.log('連線超時');
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 304) {
            console.log('CACHE NOT MODIFIED');
        } else {
            console.log('others error');
            router.push({name: '500'})
        }
    }
)

esgApi.interceptors.request.use(config => {

    const user = JSON.parse(sessionStorage.getItem('user'));
    // tenantId會從sso sessionStorage取得
    let tenantId = JSON.parse(sessionStorage.getItem('user'))?.tenantId;
    if (user && user.token) {
        config.headers.Authorization = "Bearer " + user.token;
    } else {
        // 沒有token call api會console
        // 登入時需要call api 判斷帳號的狀態，所以不印錯誤
        console.log("")
    }
    const customTenantIdHeader = 'x-tenant-id'
    config.headers["Content-Type"] = "application/json";
    if (tenantId) {
        config.headers[`${customTenantIdHeader}`] = tenantId
    }
    return config;
},
)

esgApi.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response?.status && error.response.status === 401) {
            store.dispatch('auth/setSessionExpired').then(() => {
                console.log('401 error');
                sessionStorage.clear();
                window.location.href = window.location.origin + '/login';
            });
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 403) {
            console.log('403 error');
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 404) {
            console.log('404 error');
            router.push({name: '404'});
        } else if (error.code ==='ECONNABORTED') {
            console.log('連線超時');
            return Promise.reject(error);
        } else if (error.response?.status && error.response.status === 304) {
            console.log('CACHE NOT MODIFIED');
        } else {
            console.log('others error');
            router.push({name: '500'})
        }
    }
)

export default api;
export {esgApi};