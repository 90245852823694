import api from "@/apiConfig/axiosConfig";
import { esgApi } from "@/apiConfig/axiosConfig";

export default class AccountService {

    // 取得帳號資訊
    async getAccountById(accountId) {
        const result = await api.get(`/accountSetting/findById/${accountId}`);
        return result.data;
    }

    async getAccountInfoByAccount(accountName) {
        const result = await api.post("/accountSetting/infoAccountWithRole", {"account": accountName});
        return result.data;
    }

    async putItselfAccountInfo(accountId, req) {
        const result = await api.put(`/accountSetting/updateByAdminItselfOrUserItself/${accountId}`, req);
        return result.data;
    }

    async getAccountListByCompDeptAndStatus(req) {
        const result = await api.post("/accountSetting/findUserListByCompDeptAndStatus", req);
        return result.data;
    }

    async getDepartmentByCompanyId(companyId) {
        const result = await api.get(`/department/findByCompanyId/${companyId}`);
        return result.data;
    }

    async getDepartmentByCpIdUnitname(companyId, unitName) {
        const result = await api.post("/department/findByExample", {"companyId": companyId, "unitName": unitName});
        return result.data;
    }

    async createAccount(req) {
        const result = await api.post("/accountSetting/createUser", req);
        return result.data;
    }

    async updateAccount(accountId, req) {
        const result = await api.put(`/accountSetting/update/${accountId}`, req);
        return result.data;
    }

    async updateCipherToken(accountId, req) {
        const result = await api.put(`/accountSetting/updateCipherTokenById/${accountId}`, req);
        return result.data;
    }

    async updateCipherTokenByIdFromEsg(accountId) {
        const result = await api.put(`/accountSetting/updateCipherTokenByIdFromEsg/${accountId}`);
        return result.data;
    }

    // 雙因子驗證相關
    // 取得MFA QRCode
    async apiGetMfaQRcode(accountId) {
        const result = await esgApi.post("/mfa/googleAuthQRCode", {"userId": accountId, "issuer": "ESG"});
        return result.data;
    }

    // 驗證MFA
    async apiVerifyMfaInput(req) {
        const result = await esgApi.post("/mfa/googleAuthQRCode/verify", req);
        return result.data;
    }

    // 編輯帳號MFA資訊
    async editAccountMfaInfo(updateData) {
        const result = await esgApi.put(`/accounts/mfa/${updateData?.userId}`, updateData.mfaEnabled, {
            headers: {
              'Content-Type': 'application/json', // 確保為 JSON 格式
            },
        });
        return result.data;
    }
    
}